import React from "react"
import { graphql } from "gatsby";
import { theme, siteEmail } from '../../config/website'
import styled from "styled-components"
import ProjectTile from "../components/projectTile"
import SEO from "../components/SEO/SEO"

const Container = styled.div``

const ProjectList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 16px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const Contact = styled.div`
  display: block;
  padding: 40px 0;
  text-align: center;
  font-size: 16px;

  a {
    cursor: pointer;
    text-decoration: underline;
    font-family: Bariol-Bold;
    color: ${theme.primaryColor};

    :hover, :active {
        color: inherit;
    }

    :visited {
        color: inherit;
    }
  }
`

const ProjectTileWrapper = styled.div`
  background: grey;
  display: flex;
  height: 100%;

  ::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(10/16 * 100%);

    @media (max-width: 768px) {
        padding-bottom: 100%
    }
  }
`

const Index = ({ data: { prismicWork } }) => (
  <Container className="IndexContainer">
    <SEO />
    <ProjectList>
      {prismicWork.data.projects.map((item, index) => {
        const project = {
          url: item.project.url,
          data: item.project.document[0].data
        }
        return (
          <ProjectTileWrapper key={index} >
            <ProjectTile project={project} />
          </ProjectTileWrapper>
        )
      })}
    </ProjectList>
    <Contact>Want to see more of my work? <a href={"mailto:" + siteEmail}>Email Me.</a></Contact>
  </Container>
)

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    prismicWork {
      data {
        projects {
          project {
            url
            document {
              __typename
              ...ProjectFragment
            }
          }
        }
      }
    }
  }

  fragment ProjectFragment on PrismicProject {
    data {
      title {
        text
      }
      overlay_background_color
      overlay_background_opacity
      categories {
        category {
          document {
            data {
              name
            }
          }
        }
      }
      tile_image_background_color
      tile_image {
        alt
        localFile {
          childImageSharp {
              fluid(maxWidth: 648, quality: 100) {
                ...GatsbyImageSharpFluid
              }
          }
        }
      }
    }
  }
`