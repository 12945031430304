import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import hexRgb from "hex-rgb"

const TilePlaceholder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const TileImage = styled(Img)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const Container = styled.div`
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: ${props => props.theme.color};

    user-select: none;
    transition: .3s ease;
    opacity: 0;

    &:hover {
        opacity: 1;
    }

    @media (hover: none), (hover:on-demand) {
        &:hover {
            opacity: 0;
        }
    }
}
`

const MetadataContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
`

const Title = styled.h2`
    color: white;
    margin: 8px;
    text-align: center;
    font-family: Bariol-Bold;
`

const Category = styled.h3`
    color: white;
    font-family: BariolRegular-Italic;
    font-weight: normal;
    margin: 0;
    text-align: center;
    letter-spacing: 1px;
`

class ProjectTile extends React.PureComponent {

    render() {
        const project = this.props.project
        const tileImage = project.data.tile_image

        let contentComponent
        if (tileImage == null || tileImage.localFile == null) {
            contentComponent = <TilePlaceholder />
        } else {
            const backgroundColor = project.data.tile_image_background_color || false
            contentComponent = <TileImage backgroundColor={backgroundColor} fluid={tileImage.localFile.childImageSharp.fluid} />
        }

        let categories = project.data.categories
            .filter(item => item.category != null)
            .map(item => item.category.document[0].data.name)
            .join(", ")

        let categoryComponent
        if (!categories.length) {
            categoryComponent = ""
        } else {
            categoryComponent = <Category>{categories}</Category>
        }

        const primaryRGB = hexRgb(project.data.overlay_background_color || "#000000")
        const tileOpacity = (project.data.overlay_background_opacity || 89) / 100
        const overlayTheme = {
            color: `rgba(${primaryRGB.red}, ${primaryRGB.green}, ${primaryRGB.blue}, ${tileOpacity})`
        }

        return (
            <Container onClick={this.handleClick}>
                {contentComponent}
                <Overlay theme={overlayTheme}>
                    <MetadataContainer>
                        <Title>{project.data.title.text}</Title>
                        {categoryComponent}
                    </MetadataContainer>
                </Overlay>
            </Container>
        )
    }

    handleClick = () => {
        navigate(this.props.project.url)
    }
}

export default ProjectTile